import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/blog';

import Yoast from 'components/globals/yoast';
import PaginationPosts from 'components/posts/pagination-posts';

const FutureOfWork = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPost(
        filter: {
          categories: { elemMatch: { slug: { eq: "future-of-work" } } }
        }
      ) {
        edges {
          node {
            slug
            categories {
              name
            }
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            featured_media {
              source_url
            }
          }
        }
      }

      allWordpressPage(filter: { slug: { eq: "category-business-growth" } }) {
        edges {
          node {
            wordpress_id
            acf {
              featured_article_static {
                background_image {
                  desktop {
                    source_url
                  }
                  mobile {
                    source_url
                  }
                }
                title
                copy
                link_url
                link_text
                categories {
                  category_link
                  category_title
                }
              }
            }
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);

  const postContent = data.allWordpressPost;
  const pageContent = data.allWordpressPage;

  return (
    <Layout>
      <Yoast { ...pageContent.edges[0].node.yoast_head_json } />
      <div className="cat-work-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <PaginationPosts { ...postContent } />
      </div>
    </Layout>
  );
};

export default FutureOfWork;
